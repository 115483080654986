/*******************************************
                GENERAL
*******************************************/
html {
    font-size: 62.5%;
}
body {
    font-size: 1.6rem;
}

:root {
    --main-background-color : lightgray;
    --main-color: gray;
    --secondary-background-color: #1f2258; 
    --secondary-color: #fff;
}
/********************************************
                    HEADER
********************************************/
header {
    text-align: center;
    padding: 30px 0;
}

header img {
    max-width: 90%;
}


 /*******************************************
                    FORM
*******************************************/
.return {
    margin-left: 20px;
    background-color: var(--secondary-background-color);
    color: var(--secondary-color);
}
.return:hover, .return:focus {
    color: var(--secondary-color);
    text-decoration: none;

}

.formio-form {
    width: 90%;
    padding-top: 50px;
    margin: auto ;
}
#formio {
    /*width: 100%;*/
    margin: auto;
}

.wizard-page {
    box-shadow: 0px 4px 8px rgba(0,0,0,0.2);
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
}


/*****************DATE******************/
.input-group {
    display: flex;
    align-items: center;
    width:350px
}

.input-group-text {
    padding: 6px 15px 7px;
    border-radius: 0 5px 5px 0;
    background-color: var(--main-background-color);
}

/****************NAV*********************/
nav {
    margin-bottom: 40px;
}
.page-link {
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 5px;
}

.pagination>li {
    display: inline-block;
    margin-bottom: 10px;
}


/***********BUTTON***************/
button.btn.btn-secondary.btn-wizard-nav-cancel,.formio-form>div>nav>ul.pagination .page-link {
    color: var(--main-color);
    font-weight: bold;
    border:0;
    background-color: var(--main-background-color);
}

button.btn.btn-primary.btn-wizard-nav-next, button.btn.btn-primary.btn-wizard-nav-previous, .formio-button-add-row, button.btn.btn-primary.btn-wizard-nav-submit, .formio-form>div>nav>ul.pagination .page-item.active .page-link {
    background-color:var(--secondary-background-color);
    border-color: var(--secondary-background-color);
    color: var(--secondary-color);
    font-weight: bold;
}

button.btn.btn-primary.btn-wizard-nav-next:hover, button.btn.btn-primary.btn-wizard-nav-previous:hover, .formio-button-add-row:hover, button.btn.btn-primary.btn-wizard-nav-submit:hover, .btn-primary:active, .return:hover, .return:focus {
    background-color: #444786;
    border-color: #444786;
}

/*************DATASOURCE************/
.form-group.has-feedback.formio-component.formio-component-datasource {
    display: none;
}

/***********FORM SPECIFICITY********/
a.browse {
    color: gray;
}
.list-inline {
    margin-top: 30px;
    text-align: right;
}
.formio-component-panel {
    border: 1px solid lightgray;
    border-radius: 5px;
    /*padding: 10px;*/
}

.card-header {
    background-color: var(--main-background-color);
    padding: 15px;
}
.card-body {
    padding: 10px;
}
.formio-component-lesLocaux {
    margin-bottom: 10px;
}
.formio-hidden {
    border: 0;
}

/****************DATAGRID LIEUX**********************/

.formio-component-salon td:nth-of-type(3), .formio-component-salon1 td:nth-of-type(3), .formio-component-salleAManger td:nth-of-type(3), .formio-component-salleAManger3 td:nth-of-type(3), .formio-component-cuisine td:nth-of-type(3), .formio-component-cles td:nth-of-type(5){
    width: 250px;
}
.formio-component-salleAManger3 td:first-of-type, .formio-component-cles td:first-of-type, .formio-component-cles td:nth-of-type(2){
    width:170px;
}

.formio-component-cles td:nth-of-type(3), .formio-component-cles td:nth-of-type(3) .input-group{
    width: 180px;
}
.formio-component-salon td:nth-of-type(4), .formio-component-salon1 td:nth-of-type(4), .formio-component-salleAManger td:nth-of-type(4), .formio-component-salleAManger3 td:nth-of-type(4), .formio-component-cuisine td:nth-of-type(4) {
    width:300px;
}

/************DATAGRID VEHICULE********************/

.formio-component-droite td:nth-of-type(4), .formio-component-droite th:nth-of-type(4), .formio-component-gauche td:nth-of-type(4), .formio-component-gauche th:nth-of-type(4), .formio-component-faceArriere td:nth-of-type(4), .formio-component-faceArriere th:nth-of-type(4){
    display: none;
}
.formio-component-droite td:nth-of-type(3), .formio-component-gauche td:nth-of-type(3), .formio-component-faceArriere td:nth-of-type(3) {
    width: 250px;
}
.formio-component-droite td:first-of-type, .formio-component-gauche td:first-of-type, .formio-component-faceArriere td:first-of-type {
    width:400px;
}

/*********************MODAL************************/
h3[ref='dialogHeader']{
    visibility: hidden;
}
h3[ref='dialogHeader']::after{
    content: 'Voulez-vous supprimer les changements ?';
    visibility: visible;
    display: block;
    /* position: absolute; */
}

button[ref='dialogCancelButton']{
    visibility: hidden;
}

button[ref='dialogCancelButton']::after{
    visibility: visible;
    content: 'Annuler';
    display: block;
    background-color: var(--main-background-color);
    padding: 6px 12px;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
    
}

button[ref='dialogYesButton']{
    visibility: hidden;
}
button[ref='dialogYesButton']::after{
    visibility: visible;
    content: 'Oui, supprimer';
    display: block;
    background-color: var(--secondary-background-color);
    padding: 6px 12px;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
    
}

/* button[ref='modalSave']{
    visibility: hidden;
}
button[ref='modalSave']::after{
    visibility: visible;
    display:block;
    content:'Sauvegarder';
    background-color: var(--secondary-background-color);
    padding: 6px 12px;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
} */

/***********************************************
                    HOME
***********************************************/

main {
    display:flex;
    flex-direction: column;
    max-width: 90%;
    margin: auto;
}
a, a:hover {
    padding : 5px 10px;
    text-align: center;
    margin-bottom: 1rem;
    background-color: var(--main-background-color);
    text-decoration: none;
    color: black;
    border-radius: 5px;
}


/************************************************
                QR READER
************************************************/
.reader {
    max-width: 100%;
}
.result {
    text-align: center;
}
/************************************************
                RESPONSIVE
************************************************/
@media(min-width:750px){
    .reader {
      width: 50%;
      margin: 10px auto;
    }
  
    header img {
      max-width: 30%;
    }

    header {
      text-align: left;
      padding-left: 20px;
    }
  }
  
  @media (min-width:1000px) {
    main {
        flex-direction: row;
        justify-content: center;
        margin-top : 150px;
    }
    main a:not(:last-of-type) {
        margin-right: 20px;
    }
    .reader {
      max-width: 30%;
    }
    header img {
      max-width: 20%;
    }
  }


